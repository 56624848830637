<template>
  <v-app>
    <v-navigation-drawer app permanent color="#282a36" dark fixed>
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Ridgewolf</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <SidebarMenu :items="base" class="mb-9"></SidebarMenu>

      <TextDivider>the commons</TextDivider>
      <SidebarMenu :items="commons" class="mb-9"></SidebarMenu>
      
      <TextDivider>recent</TextDivider>
      <SidebarMenu :items="recent"></SidebarMenu>

      <v-spacer></v-spacer>
    </v-navigation-drawer>

    <v-app-bar color="white" app dense flat elevate-on-scroll elevation="1">
      <h3 class="d-flex align-center">{{ pageTitle }}</h3>
      <v-spacer></v-spacer>
      <MessageCenterPopup></MessageCenterPopup>
      <ProfilePopup></ProfilePopup>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ProfilePopup from './../components/ProfilePopup'
import MessageCenterPopup from './../components/MessageCenterPopup'
import TextDivider from './../components/TextDivider'
import SidebarMenu from './../components/SidebarMenu'
import { get } from 'vuex-pathify'
import { routeNames } from '../router'

export default {
  name: 'MemberLayout',
  components: {
    ProfilePopup,
    MessageCenterPopup,
    SidebarMenu,
    TextDivider
  },
  data() {
    return {
      base: [
        {
          title: 'base camp',
          icon: 'fad fa-mountains',
          target: routeNames.member.home
        },
        {
          title: "I'm Tracking",
          icon: 'fad fa-paw',
          target: routeNames.member.tracking
        },
        {
          title: 'information desk',
          icon: 'fad fa-info-square',
          target: routeNames.member.infoDesk
        },
        {
          title: 'newsletter',
          icon: 'fad fa-newspaper',
          target: routeNames.member.newsletter
        },
        { title: 'fellow members', icon: 'fad fa-users', target: routeNames.member.fellowMembers }
      ],
      commons: [
        { title: "ridgewolf home", icon: 'fad fa-home-alt', target: routeNames.public.home },
        { title: "discover projects", icon: 'fad fa-camera-movie', target: routeNames.member.projects},
        { title: "meet the artists", icon: 'fad fa-users', target: routeNames.member.artists},
      ],
      recent: []
    }
  },
  computed: {
    pageTitle: get('app/pageTitle')
  },
  methods: {
    navigate(target) {
      if (this.$route.name !== target) {
        this.$router.push({ name: target })
      }
    }
  }
}
</script>
