<template>
  <v-container>
    <div class="text-center">
      <v-avatar size="95px">
        <img :src="picture" :alt="name" />
      </v-avatar>
      <h2 class="mt-8 mb-4">Welcome, {{ name }}</h2>
      <p>Manage your Ridgewolf member account. For your login details (name, email etc), please visit your login provider.</p>
    </div>
  </v-container>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: 'MyProfile',
  computed: {
    picture: get('auth/picture'),
    name: get('auth/name'),
    email: get('auth/email'),
    isAuthenticated: get('auth/isAuthenticated')
  }
}
</script>